const { rapidReady } = require('../../../../lib/utils/rapid')

export default (() => {
  class ContentGroup {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      // we need to add this event listener no matter if the content group  module is there or not on the first article for mounting the js for any subsequent articles which may contain this module
      document.addEventListener('contentgroup:added', this._handleInstanceAdded.bind(this))
      this._loadChatbot()
    }

    _loadChatbot() {
      window.AIS = {
        chatbotConfig: {
          options: {
            tenantName: 'aol-med',
            sessionTTL: 300000,
            terminateSessionTTL: 900000,
            userEmail: ''
          },
          chatWindow: {
            cssUrl: '',
            mediaModes: [
              {
                mode: 'desktop',
                media: '(min-width: 745px) and (min-height: 485px)'
              }
            ],
            responsiveHeight: true
          },
          chatHeader: {
            title: 'Medicare Chatbot',
            backgroundColor: '#0047FF'
          },
          chatButton: {
            label: '',
            selector: '#chat-button'
          },
          events: {
            chatWindowInitialized: () => {
              document.getElementById('chat-button').disabled = false
            },
            chatWindowOpen: () => {
              document.getElementById('chat-button').disabled = true
            },
            chatWindowClose: () => {
              document.getElementById('chat-button').disabled = false
            }
          }
        }
      }
      if (!document.getElementById('ais-chatbot')) {
        const head = document.getElementsByTagName('body')[0]
        const s = document.createElement('script')
        s.type = 'text/javascript'
        s.src =
          'https://s.aolcdn.com/membership/WAS/ais-chatbot/prod/1.0.21/client/chatbot-client.js'
        s.id = 'ais-chatbot'
        head.appendChild(s)
      }
    }

    _handleInstanceAdded({ detail }) {
      const { elem } = detail
      if (elem && !this.elems.includes(elem)) {
        this.elems.push(elem)
        this.init(elem)
        this.refreshRapid(elem)
      }
    }

    refreshRapid(elem) {
      const id = elem?.firstChild?.id
      rapidReady(rapid => {
        if (!id) return
        if (rapid.isModuleTracked(id)) {
          rapid.refreshModule(id)
        } else {
          rapid.addModulesWithViewability([id])
        }
      })
    }
  }

  return ContentGroup
})()
